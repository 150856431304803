'use strict';

angular.module('azureCostsFeApp').service('$eaDataItemProcessorChain', function() {
  var self = this;

  var ProcesserChain = function() {
    var chain = this;

    var callbacks = [];

    chain.onVisit = function(cb) {
      callbacks.push(cb);
    };

    chain.process = function(dataItems, resultBucket) {

      // inject our statistics
      resultBucket.ChainStats = [];
      callbacks.forEach(function() { resultBucket.ChainStats.push({c: 0, t: 0}); });

      // visit all items
      dataItems.forEach(function(dataItem) {

        var chainIndex = 0;

        callbacks.every(function(cb) {

          // start time
          var start = new Date();

          // process the item
          var resultItemProcessing = cb(dataItem, resultBucket);

          // end time
          var end = new Date();

          // count the items
          resultBucket.ChainStats[chainIndex].c++;
          resultBucket.ChainStats[chainIndex].t += end - start;

          // increase the index
          chainIndex++;

          // process the result
          switch(resultItemProcessing) {
            case self.Status.Skip:
              return 0;
            case self.Status.Next:
              return 1;
          }
        });
      });
    };
  };

  self.Status = {
    Next: 1,
    Skip: 0
  };

  self.createProcesserChain= function() {
    return new ProcesserChain();
  };
});
