'use strict';

angular.module('azureCostsFeApp').controller('ModalObjectPickerCtrlMixin', [ '$scope', '$modal', function($scope, $modal) {

  $scope.openObjectPickerDialog = function(title, description, getImageUrl, getDisplayName, getDetailsString, existingObjects, selectedobjects, singleSelect) {

    var modalInstance = $modal.open({
      templateUrl: 'views/modals/ac-object-picker.html',
      controller: 'ModalObjectPickerCtrl',
      resolve: {
        ExistingObjects: function() { return existingObjects; },
        SelectedObjects: function() { return selectedobjects; },
        Metadata: function() { return { Title: title, Description: description, GetImageUrl: getImageUrl, GetDisplayName: getDisplayName, GetDetailsString: getDetailsString, SingleSelect: singleSelect ? true : false }; }
      }
    });

    // returns a promise
    return modalInstance.result;
  };
}]);
