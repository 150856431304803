'use strict';

angular.module('azureCostsFeApp').controller('ModalCSVDataExportCtrl', function($scope, $modalInstance, header, enableAzureTags) {

  // load settings from cache
  $scope.csvConfiguration = localStorage.getItem('com.azure-costs.exportSettings');
  if ($scope.csvConfiguration) {
    $scope.csvConfiguration = JSON.parse($scope.csvConfiguration);
  } else {
    $scope.csvConfiguration = {
      decimalSeparator: '.',
      tagsToExport: ''
    };
  }

  $scope.enableTags = enableAzureTags;

  // offer header
  $scope.headerSelectionModel = {
    header: header,
    selectedHeader: header.map(function (h) {
      return h;
    })
  };

  $scope.ok = function () {
    localStorage.setItem('com.azure-costs.exportSettings', JSON.stringify($scope.csvConfiguration));
    if (!enableAzureTags) { $scope.csvConfiguration.tagsToExport = ''; }
    $modalInstance.close({ config: $scope.csvConfiguration, header: $scope.headerSelectionModel.selectedHeader});
  };

  $scope.cancel = function () {
    $modalInstance.dismiss('cancel');
  };
});



