'use strict';

/*
 * This service is able to handle date time manipulation when it comes to timelines
 * we are using in Azure Costs, e.g. required month for the last 12 month
 */
angular.module('azureCostsFeApp').service('$acTimelineGenerator', function(moment) {
  var self = this;

  // This function generates a list of month in the report id format (YYYY-MM). The
  // monthCount can be positive for a line of month in the future and negative
  // for a line of month in the past, e.g. createMonthList(-12) returns a list of
  // month for the last year
  self.createMonthList = function(monthCount, startDate) {
    startDate = startDate ? startDate : (new Date());

    // define the direction
    var internalMonthCount = monthCount;
    var directionForward = true;

    if (monthCount < 0) {
      internalMonthCount = (-1) * monthCount;
      directionForward = false;
    }

    // visit every month
    var monthList = [];
    for(var monthIndex = 0; monthIndex < internalMonthCount; monthIndex++) {

      // define the new moment
      var newMoment = moment(startDate).add(monthIndex, 'months');
      if (!directionForward) {
        newMoment = moment(startDate).add((-1) * monthIndex, 'months');
      }

      // generate the report
      monthList.push(newMoment.format('YYYY-MM'));
    }

    return monthList.sort();
  };

  // Allows to add a month to an existing date
  self.addMonth = function(startDate, amount) {
    return moment(startDate).add(amount ? amount : 1, 'months').toDate();
  };

  self.addDay = function(startDate, amount) {
    return moment(startDate).add(amount ? amount : 1, 'days').toDate();
  };

  self.getCurrentYear = function() {
    return moment(new Date()).year();
  }
});
