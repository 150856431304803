'use strict';

angular.module('azureCostsFeApp').service('$acDataSourceHelper', function($acDataSourceSettings, $acDataSourceClasses, $q, $eaReportData, $eaBackend, $acTimelineGenerator, $injector, $eaBackendAzureCachedContentLoader, $base64) {
  var self = this;

  self.getStoreDashboardItemsCallback = function() {
    return function(teamid, contract, dataToken, className, itemToStore, optionalParams) {
      return $eaBackend.dataPostDashboardWidget(teamid, contract, itemToStore, dataToken).then(function() {
        return $q.when(itemToStore);
      }) ;
    }
  };

  self.getDeleteDashboardItemsCallback = function() {
    return function(teamid, contract, dataToken, className, itemToDelete, optionalParams) {
      return $eaBackend.dataDeletDashboardWidget(teamid, contract, itemToDelete.uuid, dataToken);
    }
  };

  self.getQueryDashboardItemsCallback = function(dataFileName) {

    return function(teamid, contract, dataToken, className, cacheHash, optionalParams) {

      // load the static dashboard elements from our default dashboard
      return $acDataSourceSettings.loadDataFromStaticUrl(dataFileName).then(function (items) {

        // inject the result items
        var resultItems = items.map(function (item) {
          return new $acDataSourceClasses.DashboardItemClass(item);
        });

        if (contract === 'CSP:Welcome') {
          return $q.when(resultItems);
        } else {
          return $eaBackend.dataGetDashboardWidgets(teamid, contract, dataToken).then(function (loadedItems) {

            // inject the loaded items
            loadedItems.forEach(function (item) {
              resultItems.push(new $acDataSourceClasses.DashboardItemClass(item));
            });

            // done
            return $q.when(resultItems);
          });
        };
      });
    }
  };

  self.registerDataSourceDashboardItemClass = function(dataSourceManager, collectionId, dataFileName) {
    dataSourceManager.registerDataSource('global', 'DashboardItemClass', collectionId, self.getQueryDashboardItemsCallback(dataFileName), self.getStoreDashboardItemsCallback(), self.getDeleteDashboardItemsCallback());
  };

  self.registerDataSourcePeriodClass = function(dataSourceManager, collectionId) {

    dataSourceManager.registerDataSource('global', 'PeriodClass', collectionId, function (teamid, contract, dataToken, className, cacheHash, optionalParams) {

      // load the available reports
      return self.queryDataSource(teamid, contract, dataToken, 'ReportClass', optionalParams).then(function(availableReports) {

        // generate the monthlist
        var monthList = [];
        availableReports.Reports.forEach(function(r) { monthList.push(r); });
        monthList.sort();

        // extract the Pariods out of it
        var monthList = monthList.map(function(month) {
          return $acDataSourceClasses.PeriodClass({ReportId: month});
        });

        return $q.when(monthList);
      });
    });
  };

  self.registerDataSourceReportClass = function(dataSourceManager, collectionId) {

    dataSourceManager.registerDataSource('global', 'ReportClass', collectionId, function (teamid, contract, dataToken, className, cacheHash, optionalParams) {

      // load the values from the backend
      return $eaReportData.loadContractDetails(teamid, contract, dataToken, optionalParams).then(function (loadedData) {

        // generate a report model
        var reportModel = new $acDataSourceClasses.ReportClass({Contract: contract});
        reportModel.Definition = loadedData.Definition;
        reportModel.DailyReportEnbaled = loadedData.DailyReport;
        reportModel.DailyReportBreakDownFirst = loadedData.DailyReportBreakDownFirst;
        reportModel.DailyReportBreakDownSecond = loadedData.DailyReportBreakDownSecond;
        reportModel.MonthlyCostLimit = loadedData.MonthlyCostLimit;
        reportModel.Currency = loadedData.Currency;
        reportModel.CreatedAt = loadedData.CreatedAt ? new Date(loadedData.CreatedAt) : null;
        reportModel.UpdatedAt = loadedData.LastTouch ? new Date(loadedData.LastTouch) : null;
        reportModel.Token = loadedData.Token;
        reportModel.Reports = loadedData.Reports;
        reportModel.ReportsFull = loadedData.ReportsFull;

        // done
        return $q.when(reportModel);
      });
    });
  };

  self.registerDataSourceSpendingFilerClass = function(dataSourceManager, collectionId) {

    dataSourceManager.registerDataSource('global', 'SpendingFilterClass', collectionId, function (teamid, contract, dataToken/* , className, cacheHash, optionalParams*/) {
      return $eaBackend.dataGetDataFilters(teamid, contract, dataToken).then(function(availableFilters) {
        return availableFilters.map(function(e) {
          var item = new $acDataSourceClasses.SpendingFilterClass();
          item.Id = e.Id;
          item.Name = e.Name;
          item.Owner = e.Owner;
          item.Rules = e.Rules;
          item.TeamId = e.TeamId;
          return item;
        });
      });
    });
  };

  self.registerDataSourceSpendingEntryClassWithMappingCallback = function(dataSourceManager, collectionId, version, mappingCallback) {
    dataSourceManager.registerDataSource('global', 'SpendingEntryClass', collectionId, function (teamid, contract, dataToken, className, cacheHash, optionalParams) {
      return self.queryDataSource(teamid, contract, dataToken, 'ReportClass', optionalParams).then(function (reportsInfo) {

        function resourceLoadRouting(teamid, contractNumber, resourceId, cacheIdentifier, jwtToken, retry)
        {
          if (version == 1) {
            return $eaBackend.dataGetContractReportData(teamid, contractNumber, resourceId, cacheIdentifier, jwtToken, retry);
          } else {
            return $eaBackend.dataGetContractReportDataV3(teamid, contractNumber, resourceId, cacheIdentifier, jwtToken, retry);
          }
        }

        return $eaBackendAzureCachedContentLoader.dataRequestCachedResourcesWithRetry(teamid, contract, reportsInfo.ReportsFull, dataToken, {cacheIdentifierExtenion: cacheHash, resourceEndpoint: resourceLoadRouting}).then(function (loadedData) {
          return mappingCallback(teamid, contract, dataToken, reportsInfo, loadedData);
        });
      });
    });
  };

  self.registerDataSourceSpendingHistoryEntryClassWithMappingCallback = function(dataSourceManager, collectionId, version, mappingCallback) {
    dataSourceManager.registerDataSource('global', 'SpendingHistoryEntryClass', collectionId, function (teamid, contract, dataToken, className, cacheHash, optionalParams) {
      return self.queryDataSource(teamid, contract, dataToken, 'ReportClass', optionalParams).then(function (reportsInfo) {

        function resourceLoadRouting(teamid, contractNumber, resourceId, cacheIdentifier, jwtToken, retry)
        {
          if (version == 1) {
            return $eaBackend.dataGetContractReportHistory(teamid, contractNumber, jwtToken);
          } else {
            return $eaBackend.dataGetContractReportHistoryV3(teamid, contractNumber, cacheIdentifier, jwtToken, retry);
          }
        }

        // check if we have no reports
        if (reportsInfo.ReportsFull.length == 0) { return $q.when([]); }

        // generate the cache hash (last 2 months)
        var cacheHash = "";
        var reportsSorted = reportsInfo.ReportsFull.sortOn('Month');
        if (reportsSorted.length > 1) {
          cacheHash = reportsSorted[reportsInfo.ReportsFull.length - 1].CacheHash + reportsSorted[reportsInfo.ReportsFull.length - 2].CacheHash;
        } else if (reportsSorted.length > 0) {
          cacheHash = reportsSorted[reportsInfo.ReportsFull.length - 1].CacheHash
        } else {
          cacheHash = "";
        }

        // trigger the data load
        return $eaBackendAzureCachedContentLoader.dataRequestCachedResourcesWithRetry(teamid, contract, [{ Id: 'history', CacheHash: cacheHash}], dataToken, {cacheIdentifierExtenion: cacheHash, resourceEndpoint: resourceLoadRouting}).then(function (loadedData) {
          return mappingCallback(teamid, contract, dataToken, reportsInfo, loadedData);
        });
      });
    });
  };

  self.registerDataSourceSpendingEntryClassForCoreApi = function(dataSourceManager, collectionId) {

    self.registerDataSourceSpendingEntryClassWithMappingCallback(dataSourceManager, collectionId, 2, function(teamid, contract, dataToken, reportsInfo, loadedData) {

      return $eaBackend.dataGetContractTags(teamid, contract, dataToken).then(function(loadedTags) {

        // safety
        if (!loadedTags) { loadedTags = { items: []}; }
        if (!loadedTags.items) { loadedTags.items = []; }

        // support old tags
        loadedTags.items.forEach(function(tagElement) {

          try {
            // decode from base64
            var decodedKey = $base64.decode(tagElement.serviceId);
            if (decodedKey && decodedKey.split('/').length == 3) {
              var decodedKeySplit = decodedKey.split('/');
              tagElement.serviceId = ('b64:' + decodedKeySplit[0] + '/' + decodedKeySplit[2]).toLowerCase();
            }
          } catch(exc) {}
        });

        var models = [];
        var indexLoadedTags = loadedTags && loadedTags.items ? loadedTags.items.buildIndex('serviceId') : {};

        // the hash for tags
        var azureTagCaseAdaptionHash = {};

        loadedData.forEach(function(services) {

          services.forEach(function (service) {

            // This API does not support V1 Tags
            service.ServiceTagsV1 = [];
            service.ServiceTagsV2 = [];

            // we load the tags from the external API
            service.ServiceTagsV3 = [];

            if (indexLoadedTags[service.ServiceId] && indexLoadedTags[service.ServiceId].tags) {

              // load and filter tags
              var loadedTags = indexLoadedTags[service.ServiceId].tags.filter(function(value, index, self) { return self.indexOf(value) === index; });
              if (!loadedTags) { loadedTags = []; }

              // assign tags
              service.ServiceTagsV3.push.apply(service.ServiceTagsV3, loadedTags);
            }

            // add the old tags
            var tagName = ('b64:' + service.SubscriptionId + '/' + service.ServiceName).toLowerCase();
            if (indexLoadedTags[tagName] && indexLoadedTags[tagName].tags) {

              // load and filter tags
              var loadedTags = indexLoadedTags[tagName].tags.filter(function(value, index, self) { return self.indexOf(value) === index; });
              if (!loadedTags) { loadedTags = []; }

              // filter out existing tags
              loadedTags = loadedTags.filter(function(value) { return service.ServiceTagsV3.indexOf(value) === -1 });

              // assign tags
              service.ServiceTagsV3.push.apply(service.ServiceTagsV3, loadedTags);
            }

            // map all
            service.ServiceTags = service.ServiceTagsV3;

            service.ServiceDailyCosts = [];
            service.ServiceDailyQuantities = [];
            service.SchemaVersion = collectionId;

            if (service.GroupTags && Object.keys(service.GroupTags).length > 0) {

              Object.keys(service.GroupTags).forEach(function (k) {

                // check if we had the tag before
                if (!azureTagCaseAdaptionHash[k.toUpperCase()]) {
                  azureTagCaseAdaptionHash[k.toUpperCase()] = k;
                }

                // get the correct tagname just in case of case sensitive duplicates
                var correctedTagName = 'AzureTag:' + azureTagCaseAdaptionHash[k.toUpperCase()];
                service[correctedTagName] = service.GroupTags[k];
              });
            }

            // Ensure the meters are an empty valid array
            service.ServiceMeters = [];

            // add the model
            models.push(service);
          });
        });

        return $q.when(models);
      });
    });
  };

  self.queryDataSource = function(teamId, contract, dataToken, dataSourceClass, optionalParams) {
    // inject dynamically to prevent circular dependency
    var $eaDataCoordinator = $injector.get('$eaDataCoordinator');

    // load the available reports
    return $eaDataCoordinator.query(teamId, contract, dataToken, dataSourceClass, optionalParams);
  };

  self.getCurrencySymbolFromCurrency = function(currency) {

    if ( currency === 'EUR') {
      return '€';
    } else if ( currency === 'USD') {
      return '$';
    } else {
      return currency;
    }
  }
});
