'use strict';

angular.module('azureCostsFeApp').controller('CspResellerRegisterCtrlMixin', function($scope, $modal) {

  $scope.openCspResellerRegisterDialog = function() {

    var modalInstance = $modal.open({
      templateUrl: "views/csp/resellers/modals/ac_modal_reseller_register.html",
      controller: 'CspResellerRegisterCtrl'
    });

    // returns a promise
    return modalInstance.result;
  };
});
