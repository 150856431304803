'use strict';

angular.module('azureCostsFeApp').service('$acDataSourceWidgetFunctions', function() {
  var self = this;

  var localFunction = {};

  // calculates the series value for the history diagram
  localFunction['func:0B851B23-0FA1-4450-A859-7861B3F1E01D'] = function(dasboardItemScope) {
    var days = [];
    for (var startDay = -29; startDay <= 0; startDay++) {
      days.push(moment().add(startDay, 'days').format('YYYY-MM-DD'));
    }
    return days;
  };

  self.callWidgetFunction = function(functionId, dasboardItemScope, args) {
    var localfunc = localFunction[functionId];
    if (!localfunc) {
      return null;
    } else {
      return localfunc(dasboardItemScope);
    }
  };
});
