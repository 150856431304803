'use strict';

angular.module('azureCostsFeApp').controller('ModalUpgradePlanCtrlMixin', function($scope, $modal) {

  $scope.openUpgradePlanWizard = function(requiredPlan, currentTeamModel, description) {

    var modalInstance = $modal.open({
      templateUrl: 'views/modals/ac-upgrade-plan.html',
      controller: 'ModalUpgradePlanCtrl',
      resolve: {
        team: function() { return currentTeamModel; },
        plan: function() { return requiredPlan; },
        description: function() { return description; }
      }
    });

    // returns a promise
    return modalInstance.result;
  };
});
