'use strict';

/*
 * This service is able to handle date time manipulation when it comes to timelines
 * we are using in Azure Costs, e.g. required month for the last 12 month
 */
angular.module('azureCostsFeApp').service('$acCacheIdentifierGenerator', function($base64, jwtHelper, rfc4122) {
  var self = this;

  self.buildCacheIdentifier = function (dataTokenJwt, reportCacheHash, profileCacheHash) {

    // generate a new jwtUserId just in case the jwt token is invalid
    var jwtUserId = $base64.encode((new Date()).toDateString());

    // decode the given data token and override the userid
    if (dataTokenJwt) {
      var jwtDecoded = jwtHelper.decodeToken(dataTokenJwt);
      if (jwtDecoded && jwtDecoded.uid) {
        jwtUserId = jwtDecoded.uid;
      }
    }

    // check if we have a profile cache hash
    if (profileCacheHash) {
      jwtUserId += profileCacheHash;
    }

    // attache the report cache hash as well
    return $base64.encode(reportCacheHash) + '.' + jwtUserId;
  };

  self.updateDailyCacheLocalInjector = function(localInjector) {
    var v = rfc4122.v4();
    var localInjectorKey = 'ac.caching.injectors.' + localInjector;
    localStorage.setItem(localInjectorKey, v);
    return v;
  };

  self.buildDailyCacheIdentifier = function(localInjector) {

    // build the reference date
    var refDate = moment().format('YYYY-MM-DD');

    // read the local injector id
    var localInjectorKey = 'ac.caching.injectors.' + localInjector;
    var localInjectorValue = localStorage.getItem(localInjectorKey) || self.updateDailyCacheLocalInjector(localInjector);

    // generate the new cache value
    return $base64.encode(localInjectorValue + ':' + refDate);
  }
});
