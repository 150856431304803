'use strict';

/*
 * This service is able to handle date time manipulation when it comes to timelines
 * we are using in Azure Costs, e.g. required month for the last 12 month
 */
angular.module('azureCostsFeApp').service('$acLocaleService', function() {
  var self = this;

  self.getDecimalSeperator = function() {
    var n = 1.1;
    n = n.toLocaleString().substring(1, 2);
    return n;
  }

});
