'use strict';

angular.module('azureCostsFeApp').controller('ModalCSVDataExportCtrlMixin', function($scope, $modal) {

  $scope.openExportWizard = function(header, enableAzureTags) {

    var modalInstance = $modal.open({
      templateUrl: 'views/modals/ac-csv-data-export.html',
      controller: 'ModalCSVDataExportCtrl',
      resolve: {
        header: function() {
          return (header ? header : []);
        },
        enableAzureTags: function() {
          return enableAzureTags ? enableAzureTags : false;
        }
      }
    });

    // returns a promise
    return modalInstance.result;
  };
});
