'use strict';

angular.module('azureCostsFeApp').service('$eaTemplateCachingStrategy', ['$eaConfig', function($eaConfig) {
  var self = this;

  self.processRequest = function(requestConfig) {

    // patch the uri
    if (requestConfig.url.indexOf('?') === -1) {
      requestConfig.url = requestConfig.url + '?v=' + $eaConfig.deployment.deploymentUUID;
    } else {
      requestConfig.url = requestConfig.url + '&v=' + $eaConfig.deployment.deploymentUUID;
    }

    // done
    return requestConfig;
  };
}]);
